import BaseService from '@/services/BaseService'

class DomesticService extends BaseService {
  constructor() {
    super()
  }
  async getDomestic(opts = {}) {
    this.endPoint = 'api/v2/logistic-rates'
    return await this.get(opts)
  }

  //create 
  async createDomestic (data= {}) {
    this.endPoint = 'api/v2/logistic-rates'
    return this.post(data).then(response => {
      return response.data 
    })
  }

  //edit
  async editDomestic(id, data= {}) {
    this.endPoint = `api/v2/logistic-rates/${id}`
    return this.put(data).then(response => {
      return response.data
    })
  }

  //delete
  async deleteDomestic(id) {
    this.endPoint = 'api/v2/logistic-rates'
    return this.delete(id)
  }

  //bulk delete
  async bulkDelete(opts) {
    this.endPoint = 'api/v2/logistic-rates/multiple-delete'
    return this.bulkDeleteGlobalSetting(opts)
  }

  //post import
  async importData(data, params = {}) {
    this.endPoint = 'api/v2/logistic-rates/import'
    return this.post(data, params).then(response => {
      return response.data
    })
  }
}
export default new DomesticService()