import Component from 'vue-class-component'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import filter from 'lodash/filter'
import join from 'lodash/join'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import sortBy from 'lodash/sortBy'
import upperCase from 'lodash/upperCase'
import lowerCase from 'lodash/lowerCase'
import { Watch } from 'vue-property-decorator'
import findIndex from 'lodash/findIndex'

// Base Page
import BasePage from '@/pages/Base'

// Services
import CountryService from '@/services/Country'
import DomesticService from '@/services/DomesticShipping'
import CourierService from '@/services/Courier'
import CityService from '@/services/City'
import ProvinceService from '@/services/Province'
import Currency from '@/services/Currency'

@Component({})
export default class DomesticShipping extends BasePage {
  constructor() {
    super()
  }
    courierDialog: boolean = false
    courierDialogData: any = {}
    useAsEdit: boolean = false
    selectedEditId: string | number = null
    detailsRateDialog: boolean = false
    selectedDomestiC: any = null
    showDeleteModal: boolean = false
    modalBulkDelete: boolean = false
    modalImportData: boolean = false
    selectedDelete: any = null
    showDomestiC: boolean = false
    domestic: any = {
      selected: []
    }

    //insurance
    insurance: boolean = false

    //city
    getCityLoading: boolean = false
    itemOriginName: any[] = []
    itemOriginKeyword: any = null
    searchDestinationDebounce: Function

    //province
    getProvinceLoading: boolean = false
    itemDestinationKeyword: any = null
    searchOriginDebounce: Function
    itemDestinationName: any[] = []

    //Courier
    getCourierLoading: boolean = false
    itemCurrency: any = []
    courierKeywords: any = null
    searchCourierDebounce: Function

    //Currency
    getCurrenciesLoading: boolean = false
    itemCourier: any = []
    currencyKeywords: any = null
    searchCurrencyDebounce: Function

    cargoModal: any = {
      open: false
    }
    expandSelectIndex: number
    id_rates_deleted: object[] = []

    //search origin & destination
    searchOrigin: string = 'Search By Origin...'
    searchDestination: string = 'Search By Destination...'
    searchLoadingOri: boolean = false
    searchInputOri: string = ''
    searchLoadingDes: boolean = false
    searchInputDes: string = ''
    searchHintOrigin: string = 'input minimum 4 letters to search'
    searchHintDestination: string = 'input minimum 4 letters to search'
    searchIcon: string = 'search'

    //add new courier
    inputDomesticCourierNew: string = null
    inputCourierItem: boolean = false

    //Table
    global_var_country_id: string
    id_routes_deleted: object[] = []
    tableHeaders: object[] = [
      {
        text: 'Country Name',
        align: 'left',
        sortable: false,
        value: 'id'
      }
    ]
    tableItems: any[] = []
    officialRouteFromDb: any[] = []
    tableTotalItems: string | number = 0
    tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
    tablePagination: any = {
      sortBy: 'name',
      page: 1,
      rowsPerPage: 10,
      descending: true,
      totalItems: 0
    }
    tableLoading: boolean = false
    getCountriesLoading: boolean = false
    getDestCountryLoading: boolean = false
    getOneCityLoading = false
    getOneProvinceLoading = false
    searchFilterParamsCountryKeyword: string = ''
    titleDialog: string = ''
    disableOrigin: boolean = true
    
    // by Courier Rates
    tableHeadersCourier: object[] = [
      {
        text: 'Courier Name',
        align: 'center',
        sortable: false,
        value: 'couriername'
      },
      // {
      //     text: 'Shipping Method',
      //     align: 'center',
      //     sortable: false,
      //     value: 'shippingmethod'
      // },
      {
        text: 'Name',
        align: 'center',
        sortable: false,
        value: 'name'
      },
      // {
      //     text: 'Origin Country',
      //     align: 'center',
      //     sortable: false,
      //     value: 'origincountry'
      // },
      {
        text: 'Origin Name',
        align: 'center',
        sortable: true,
        value: 'originname'
      },
      // {
      //     text: 'Destination Country',
      //     align: 'center',
      //     sortable: false,
      //     value: 'destinationcountry'
      // },
      // {
      //     text: 'Destination Method',
      //     align: 'center',
      //     sortable: false,
      //     value: 'destinationmethod'
      // },
      {
        text: 'Destination Name',
        align: 'center',
        sortable: true,
        value: 'destinationname'
      },
      {
        text: 'Remark',
        align: 'center',
        sortable: true,
        value: 'remark'
      },
      {
        text: 'Rate',
        align: 'center',
        sortable: true,
        value: 'rate'
      },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        value: 'ratedetails'
      },
      {
        text: 'Action',
        align: 'center',
        sortable: false,
        value: 'action',
        class: 'action-table-width'
      }
    ]
    rateDetailsObject: any = {}
    itemsUnit: any = ['Kg', 'Gr']
    itemsCalculation: any = ['FLAT', 'CUSTOM']
    itemOriginMethod: any = ['Cities', 'Provinces']
    itemDestinationMethod: any = ['Cities', 'Provinces']
    itemImportOrigin: any = ['City', 'Province']
    itemImportDestination: any = ['City', 'Province']
    // itemShipType: any = ['International','Domestic']
    itemShipMethod: any = ['Courier', 'Cargo']
    itemDestName: any[] = []
    tableDomesticCourier: any[] = []
    tableTotalItemsCourier: string | number = 0
    tableRowsPerPageItemsCourier: number[] = [5, 10, 15, 25, 50, 100]
    tablePaginationCourier: any = {
      sortBy: 'created_at',
      page: 1,
      rowsPerPage: 10,
      descending: true,
      totalItems: 0
    }
    pagination: any = {
      sortBy: 'id'
    }
    checkboxAllSelected: boolean = false
    dataDomesticAllSelected: any = {}
    selectedId: any = []
    modelImportData: any = []
    importDataDialog: any = {}
    tableLoadingCourier: boolean = false
    itemCountry: any = []
    tableItemsCargo: any[] = []
    tableTotalItemsCargo: string | number = 0
    tableRowsPerPageItemsCargo: number[] = [5, 10, 15, 25, 50, 100]
    tablePaginationCargo: any = {
      sortBy: 'name',
      page: 1,
      rowsPerPage: 10,
      descending: true,
      totalItems: 0
    }
    tableLoadingCargo: boolean = false
    selectedCountry: string = ''
    async mounted() {
      this.getCurrency()
      this.courierJNE()
      this.searchCurrencyDebounce = debounce(this.getCurrency, 500)
      this.searchCourierDebounce = debounce(this.getCourier, 500)
      this.searchOriginDebounce = debounce(this.getOriginName, 500)
      this.searchDestinationDebounce = debounce(this.getDestinationName, 500)
    }
    @Watch('itemOriginKeyword')
    @Watch('itemDestinationKeyword')
    onitemNameChange() {
      this.searchOriginDebounce()
      this.searchDestinationDebounce()
    }
    @Watch('courierKeywords')
    onCourierChange() {
      // this.searchCourierDebounce()
      if (this.courierKeywords != this.courierDialogData.courier_id && this.courierKeywords != 'Others...') {
        this.getCourier(null)
      }
    }
    @Watch('currencyKeywords')
    onCurrencyChanged() {
      this.searchCurrencyDebounce()
    }
    @Watch('tablePagination', { deep: true })
    @Watch('searchInput', { deep: true })
    onChanged() {
      this.$vuetify.goTo(0)
      this.getCountry()
    }

    @Watch('tablePaginationCourier', { deep: true})
    onChangeCourier() {
      this.getDomesticCourier()
    }
    
    @Watch('courierDialogData.shipping_type')
    onChangeShippingType() {
      // if (this.courierDialogData.shipping_types == 'Domestic'){
      //     this.itemOriginMethod = ['Cities', 'Provinces'],
      //     this.itemDestinationMethod = ['Cities', 'Provinces']
      // }
    }

    @Watch('searchInput')
    searchFilterChanged(newData: any) {
      this.tablePagination.page = 1
      this.searchFilterParamsCountryKeyword = newData.search
      this.getCountry()
    }

    closeExpand() {
      forEach((this.$refs as any).tableDomestic.expanded, (item, key) => {
        (this.$refs as any).tableDomestic.expanded[key] = false
      })
    }

    async refreshSearch() {
      await this.getDomesticCourier()
    }

    async onClearSearchDes() {
      this.searchInputDes = '',
      await this.refreshSearch()
    }

    async onClearSearchOri() {
      this.searchInputOri = '',
      await this.refreshSearch()
    }

    async theTableExpand(params, index) {
      this.getCourier(params.id)
      this.getCountries()
      this.getDestinationCountries()
      this.selectedCountry = params.id
      this.getDomesticCourier()
    }

    async getCountry() {
      const opts: any = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          'sort': this.tablePagination.sortBy,
          'filter[name][like]': this.searchFilterParamsCountryKeyword
        }
      }
      try {
        this.tableLoading = true
        const response: any = await CountryService.getCountries(opts)
        this.global_var_country_id = join(map(response.data.data, 'id'), ',')
        if (response.data.success) {
          this.tableTotalItems = response.data.meta.pagination.total
          this.tableItems = []
          for (let dataCountry of response.data.data) {
            var domesticCourier = []
            var country = {
              id: dataCountry.attributes.id,
              nameCountry: dataCountry.attributes.name,
              currencyCountry: dataCountry.attributes.currency
              // domesticCourier: domesticCourier,
            }
            const findIndexCountry = findIndex(this.tableItems, (table) => table.id === country.id)
            if (findIndexCountry === -1 || findIndexCountry === undefined) {
              this.tableItems.push(country)
            } else {
              this.tableItems[findIndexCountry] = country
            }
            this.checkboxAllSelected = false
          }
          // get domestic courier
          // for (let domestic of this.tableItems) {
          //     domestic.domesticCourier = await this.getDomesticCourier(domestic.dataCountry)
          // }
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoading = false
      }
    }

    // async getDomesticCourier(data, dataIncluded) {
    async getDomesticCourier(){
      try {
        this.tableLoadingCourier = true
        const optsDomestic: any = {
          params: {
            'filter[origin_country_id][in]': this.global_var_country_id,
            'page[limit]': this.tablePaginationCourier.rowsPerPage,
            'page[num]': this.tablePaginationCourier.page,
            'filter[shipping_types][is]': 'Domestic',
            sort: this.tablePaginationCourier.descending ? '-' + this.tablePaginationCourier.sortBy : this.tablePaginationCourier.sortBy,
            include: 'country,courier,destinationCountry,destinationProvince,destinationCity'
          }
        }
        if (this.searchInputOri != null && this.searchInputOri != '') {
          const optsOri: any = {
            params: {
              'filter[name][like]': this.searchInputOri,
              'filter[country_id][is]': this.courierDialogData.origin_country_id
            }
          }
          const responseCityOri = await CityService.getCities(optsOri)
          var idCityOri = join(map(responseCityOri.data.data, 'id'), ',')
          const responseProvOri = await ProvinceService.getProvinces(optsOri)
          var idProvOri = join(map(responseProvOri.data.data, 'id'), ',')
          optsDomestic.params = {
            ...optsDomestic.params,
            'filter[origin_id][in]': join([idCityOri, idProvOri], ',')
          }
        } else {
          var textFilter = 'filter[origin_id][in]'
          delete optsDomestic.params[textFilter]
        }
        if (this.searchInputDes != null && this.searchInputDes != '') {
          const optsDes: any = {
            params: {
              'filter[name][like]': this.searchInputDes,
              'filter[country_id][is]': this.courierDialogData.origin_country_id
            }
          }
          const responseCityDes = await CityService.getCities(optsDes)
          const idCityDes = join(map(responseCityDes.data.data, 'id'), ',')
          const responseProvDes = await ProvinceService.getProvinces(optsDes)
          const idProvDes = join(map(responseProvDes.data.data, 'id'), ',')
          optsDomestic.params = {
            ...optsDomestic.params,
            'filter[destination_id][in]': join([idCityDes, idProvDes], ',')
          }
        } else {
          var textFilter = 'filter[destination_id][in]'
          delete optsDomestic.params[textFilter]
        }
        const responseDomestic = await DomesticService.getDomestic(optsDomestic)
        const responseDataDomestic = responseDomestic.data.data
        const dataIncluded = responseDomestic.data.included
        const data: any = filter(responseDataDomestic, domestic => Number(domestic.attributes.origin_country_id) === Number(this.selectedCountry))
        this.tableTotalItemsCourier = responseDomestic.data.meta.pagination.total
        this.tableDomesticCourier = []
        // var dataDomCourier = []
        forEach(data, async (dataDomestic, index) => {
          // if (lowerCase(dataDomestic.attributes.shipping_types) == 'domestic') {
          const domestic = dataDomestic.attributes
          const courierName = dataIncluded.courier[dataDomestic.attributes.courier_id].attributes.name
          const countryName = dataIncluded.country[dataDomestic.attributes.origin_country_id].attributes.name
          const destCountry = await this.getDestCountry(dataDomestic.attributes.destination_country_id)
          var originName = ''
          var destinationName = ''
          if (lowerCase(dataDomestic.attributes.origin_type) == 'cities' || lowerCase(dataDomestic.attributes.origin_type) == 'city') {
            originName = await this.getOneCities(dataDomestic.attributes.origin_id)
          } else if (lowerCase(dataDomestic.attributes.origin_type) == 'provinces' || lowerCase(dataDomestic.attributes.origin_type) == 'province') {
            originName = await this.getOneProvinces(dataDomestic.attributes.origin_id)
          }
          if (lowerCase(dataDomestic.attributes.destination_type) == 'country') {
            destinationName = await this.getDestCountry(dataDomestic.attributes.destination_id)
          } else if (lowerCase(dataDomestic.attributes.destination_type) == 'cities' || lowerCase(dataDomestic.attributes.origin_type) == 'city') {
            destinationName = await this.getOneCities(dataDomestic.attributes.destination_id)
          } else if (lowerCase(dataDomestic.attributes.destination_type) == 'provinces' || lowerCase(dataDomestic.attributes.origin_type) == 'province') {
            destinationName = await this.getOneProvinces(dataDomestic.attributes.destination_id)
          }
          domestic.courier_name = courierName
          domestic.origin_country_name = countryName
          domestic.destination_country_name = destCountry
          domestic.origin_name = originName
          domestic.destination_name = destinationName
          // this.tableDomesticCourier.push(domestic)
          // dataDomCourier.push(domestic)
          // }
          const findIndexDomestic = findIndex(this.tableDomesticCourier, (table) => table.id === domestic.id)
          if (findIndexDomestic === -1 || findIndexDomestic === undefined) {
            this.tableDomesticCourier.push(domestic)
          } else {
            this.tableDomesticCourier[findIndexDomestic] = domestic
          }
        })
        // return dataDomCourier   
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingCourier = false
      }
    }
    // }

    getOriginName() {
      if (lowerCase(this.courierDialogData.origin_type) == 'cities') {
        this.getOriginCities()
      } else if (lowerCase(this.courierDialogData.origin_type) == 'provinces') {
        this.getOriginProvinces()
      }
    }

    getDestinationName() {
      if (lowerCase(this.courierDialogData.destination_type) == 'cities') {
        this.getDestinationCities()
      } else if (lowerCase(this.courierDialogData.destination_type) == 'provinces') {
        this.getDestinationProvinces()
      }
    }

    async getDestCountry(id) {
      this.getDestCountryLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 25 // Calling all country. Reducing constant filter request to the server
          }
        }
        const response = await CountryService.getOneCountry(id, opts)
        const responseData = response.data.data
        const destCountry = responseData.attributes.name
        return destCountry
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getDestCountryLoading = false
      }
    }

    async getOriginCities() {
      this.getCityLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][is]': this.courierDialogData.origin_country_id
          }
        }
        if (!isEmpty(this.itemOriginKeyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.itemOriginKeyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await CityService.getCities(opts)
        const responseData = response.data.data
        this.itemOriginName = []
        for (const city of responseData) {
          // this.itemOriginName.push(city.attributes)
          this.itemOriginName.push({
            name: city.attributes.name,
            id: city.attributes.id
          })
        }
        this.itemOriginName = sortBy(this.itemOriginName, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCityLoading = false
      }
    }

    async getOneCities(id) {
      this.getOneCityLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 25 // Calling all country. Reducing constant filter request to the server
          }
        }
        const response = await CityService.getOneCities(id, opts)
        const responseData = response.data.data
        const citiesName = responseData.attributes.name
        return citiesName
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getOneCityLoading = false
      }
    }

    async getOriginProvinces() {
      this.getProvinceLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][is]': this.courierDialogData.origin_country_id
          }
        }
        if (!isEmpty(this.itemOriginKeyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.itemOriginKeyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await ProvinceService.getProvinces(opts)
        const responseData = response.data.data
        this.itemOriginName = []
        for (const province of responseData) {
          // this.itemOriginName.push(province.attributes)
          this.itemOriginName.push({
            name: province.attributes.name,
            id: province.attributes.id
          })
        }
        this.itemOriginName = sortBy(this.itemOriginName, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getProvinceLoading = false
      }
    }

    async getOneProvinces(id) {
      this.getOneProvinceLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 25 // Calling all country. Reducing constant filter request to the server
          }
        }
        const response = await ProvinceService.getOneProvinces(id, opts)
        const responseData = response.data.data
        const provinceName = responseData.attributes.name
        return provinceName
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getOneProvinceLoading = false
      }
    }

    async getDestinationCities() {
      this.getCityLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][is]': this.courierDialogData.destination_country_id
          }
        }
        if (!isEmpty(this.itemDestinationKeyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.itemDestinationKeyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await CityService.getCities(opts)
        const responseData = response.data.data
        this.itemDestinationName = []
        for (const city of responseData) {
          // this.itemDestinationName.push(city.attributes)
          this.itemDestinationName.push({
            name: city.attributes.name,
            id: city.attributes.id
          })
        }
        this.itemDestinationName = sortBy(this.itemDestinationName, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCityLoading = false
      }
    }
    async getDestinationProvinces() {
      this.getProvinceLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][is]': this.courierDialogData.destination_country_id
          }
        }
        if (!isEmpty(this.itemDestinationKeyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.itemDestinationKeyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await ProvinceService.getProvinces(opts)
        const responseData = response.data.data
        this.itemDestinationName = []
        for (const province of responseData) {
          // this.itemDestinationName.push(province.attributes)
          this.itemDestinationName.push({
            name: province.attributes.name,
            id: province.attributes.id
          })
        }
        this.itemDestinationName = sortBy(this.itemDestinationName, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getProvinceLoading = false
      }
    }
    async getCountries() {
      this.getCountriesLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
          }
        }
        const response = await CountryService.getCountries(opts)
        const responseData = response.data.data
        this.itemCountry = []
        for (const country of responseData) {
          this.itemCountry.push(country.attributes)
        }
        this.itemCountry = sortBy(this.itemCountry, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCountriesLoading = false
      }
    }
    async getDestinationCountries() {
      this.getCountriesLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
          }
        }
        const response = await CountryService.getCountries(opts)
        const responseData = response.data.data
        this.itemDestinationName = []
        for (const country of responseData) {
          this.itemDestinationName.push(country.attributes)
        }
        this.itemDestinationName = sortBy(this.itemDestinationName, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCountriesLoading = false
      }
    }
    async getCourier(idCountry) {
      this.getCourierLoading = true
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][in]': idCountry
          }
        }
        if (!isEmpty(this.courierKeywords)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.courierKeywords
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await CourierService.getCouriers(opts)
        const responseData = response.data.data
        this.itemCourier = []
        if(response.data.success){
          this.itemCourier.push({
            value: 'oth',
            text: 'Others...'
          })
        }
        for (const courier of responseData) {
          // this.itemCourier.push(courier.attributes)
          this.itemCourier.push({
            text: courier.attributes.name,
            value: courier.attributes.id
          })
        }
        this.itemCourier = sortBy(this.itemCourier, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCourierLoading = false
      }
    }

    async courierJNE(){
      this.getCourierLoading = true
      try{
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name_slug][is]': 'jne'
          }
        }
        const response = await CourierService.getCouriers(opts)
        const responseJNE = response.data.data[0].attributes
        this.courierDialogData.courier_id = responseJNE.id
        this.itemCourier.push(responseJNE)
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCourierLoading = false
      }
    } 

    async getCurrency() {
      this.getCurrenciesLoading = true
      try {
        const opts: any = {
          params: {
            'filter[currency][null]': false,
            'page[num]': 1,
            'page[limit]': 10, // Calling all country. Reducing constant filter request to the server
            'filter[currency][like]': ''
          }
        }
        if (!isEmpty(this.currencyKeywords)) {
          var textFilter = 'filter[currency][like]'
          opts.params[textFilter] = this.currencyKeywords
          // opts.params.searchJoin = 'and'
        } else {
          var textFilter = 'filter[currency][like]'
          delete opts.params[textFilter]
          // delete opts.params.searchJoin
        }
        const response = await CountryService.getCountries(opts)
        const responseData = response.data.data
        this.itemCurrency = []
        for (const country of responseData) {
          this.itemCurrency.push(country.attributes.currency)
        }
        this.itemCurrency = sortBy(this.itemCurrency, 'currency')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.getCurrenciesLoading = false
      }
    }

    async addCourierRates() {
      try {
        const validationResponse = await this.$validator.validateAll('addNewRatesCourier')
        if (validationResponse) {
          this.showLoading({ text: 'Creating...' })
          this.courierDialog = false
          // var payload_insurance = 0
          // if(this.insurance === true){
          //     payload_insurance = 1
          // }
          // var split = this.courierDialogData.insurance_percentage.split('%')
          // console.log(this.courierDialogData,'yyy')
          const courierDialogData = {
            shipping_types: this.courierDialogData.shipping_types,
            shipping_method: lowerCase(this.courierDialogData.shipping_method),
            courier_id: this.courierDialogData.courier_id === 'oth' ? '' : this.courierDialogData.courier_id,
            origin_country_id: this.courierDialogData.origin_country_id,
            origin_id: this.courierDialogData.origin_id,
            origin_type: lowerCase(this.courierDialogData.origin_type),
            destination_country_id: this.courierDialogData.destination_country_id,
            destination_id: this.courierDialogData.destination_id,
            destination_type: this.courierDialogData.destination_type,
            rate: this.courierDialogData.rate,
            currency: this.courierDialogData.currency,
            rate_per_weight: this.courierDialogData.rate_per_weight,
            weight_unit: lowerCase(this.courierDialogData.weight_unit),
            minimum: this.courierDialogData.minimum,
            maximum: this.courierDialogData.maximum,
            calculation: lowerCase(this.courierDialogData.calculation),
            remarks: this.courierDialogData.remarks,
            name: this.courierDialogData.name,
            base_price: this.courierDialogData.base_price,
            // insurance_percentage: this.courierDialogData.insurance_percentage/100*100,
            insurance: this.insurance ? this.courierDialogData.insurance_percentage/100 : 0,
            relationships: []
          }
          if (this.courierDialogData.courier_id === 'oth') {
            courierDialogData.relationships = [
              {
                type: 'courier',
                courier_name: this.inputDomesticCourierNew
              }
            ]
          }
          await DomesticService.createDomestic(courierDialogData)
          // console.log('add', courierDialogData)
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
        } else {
          this.showSnackbar({
            text: 'Please check all fields requirements',
            color: 'red',
            timeout: 2000
          })
        }
        this.getDomesticCourier()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    openCreateCourier(propsCountry) {
      this.titleDialog = 'Add New Rates'
      this.courierDialogData = {
        shipping_types: 'Domestic',
        shipping_method: 'Courier',
        origin_country_id: propsCountry.item.id,
        origin_id: '',
        origin_type: 'Cities',
        destination_country_id: propsCountry.item.id,
        destination_id: '',
        destination_type: 'Cities',
        rate: '',
        currency: propsCountry.item.currencyCountry,
        rate_per_weight: '1',
        weight_unit: 'Kg',
        minimum: '1',
        maximum: '10',
        calculation: 'FLAT',
        remarks: '1-2 days',
        name: 'REG',
        base_price: '0',
        insurance_percentage: '0'
      }
      this.insurance = false
      if (propsCountry.item.id == 99) {
        this.courierJNE()
        // this.courierDialogData.courier_id = 1671
        // this.itemCourier.push({
        //     text: 'JNE',
        //     value: 1671
        // })
      } else {
        this.courierDialogData.courier_id = ''
      }
      this.itemCurrency.push(propsCountry.item.currencyCountry)
      this.getOriginName()
      this.getDestinationName()
      this.courierDialog = true
      this.useAsEdit = false
      this.inputCourierItem = false
    }

    async openEditDialog(props: any) {
      this.titleDialog = 'Edit Rates'
      // var split = props.item.insurance_percentage.split('%')
      // console.log(props,'yyy')
      this.courierDialogData = {
        shipping_types: startCase(props.item.shipping_types),
        shipping_method: startCase(props.item.shipping_method),
        courier_id: props.item.courier_id,
        origin_country_id: props.item.origin_country_id,
        origin_id: props.item.origin_id,
        origin_type: startCase(props.item.origin_type),
        destination_country_id: props.item.destination_country_id,
        destination_id: props.item.destination_id,
        destination_type: startCase(props.item.destination_type),
        rate: props.item.rate,
        currency: props.item.currency,
        rate_per_weight: props.item.rate_per_weight,
        weight_unit: startCase(props.item.weight_unit),
        minimum: props.item.minimum,
        maximum: props.item.maximum,
        calculation: upperCase(props.item.calculation),
        remarks: props.item.remarks,
        name: props.item.name,
        courier_name: props.item.courier_name,
        origin_name: props.item.origin_name,
        destination_name: props.item.destination_name,
        base_price: props.item.base_price,
        insurance_percentage: props.item.insurance*100
      }
      this.insurance = props.item.insurance ? true : false
      this.itemCurrency.push(props.item.currency)
      this.itemCourier.push({
        text: props.item.courier_name,
        value: props.item.courier_id
      })
      this.itemOriginName.push({
        name: props.item.origin_name,
        id: props.item.origin_id
      })
      this.itemDestinationName.push({
        name: props.item.destination_name,
        id: props.item.destination_id
      })
      this.onChangeShippingType()
      this.selectedEditId = props.item.id
      this.useAsEdit = true
      this.courierDialog = true
      this.inputCourierItem = false
    }

    async updateCourierRates() {
      try {
        const validationResponse = await this.$validator.validateAll('addNewRatesCourier')
        if (validationResponse) {
          this.showLoading({ text: 'Saving...' })
          // var payload_insurance = 0
          // if(this.insurance === true){
          //     payload_insurance = 1
          // }
          // var split = this.courierDialogData.insurance_percentage.split('%')
          // console.log('courier', this.courierDialogData)
          var courierDialogData = {
            shipping_types: this.courierDialogData.shipping_types,
            shipping_method: this.courierDialogData.shipping_method,
            courier_id: this.courierDialogData.courier_id === 'oth' ? '' : this.courierDialogData.courier_id,
            origin_country_id: this.courierDialogData.origin_country_id,
            origin_id: this.courierDialogData.origin_id,
            origin_type: this.courierDialogData.origin_type,
            destination_country_id: this.courierDialogData.destination_country_id,
            destination_id: this.courierDialogData.destination_id,
            destination_type: this.courierDialogData.destination_type,
            rate: this.courierDialogData.rate,
            currency: this.courierDialogData.currency,
            rate_per_weight: this.courierDialogData.rate_per_weight,
            weight_unit: this.courierDialogData.weight_unit,
            minimum: this.courierDialogData.minimum,
            maximum: this.courierDialogData.maximum,
            calculation: this.courierDialogData.calculation,
            remarks: this.courierDialogData.remarks,
            name: this.courierDialogData.name,
            base_price: this.courierDialogData.base_price,
            // insurance_percentage: Number(split[0])/100,
            insurance: this.insurance ? this.courierDialogData.insurance_percentage/100 : 0,
            relationships: []
          }
          if (this.courierDialogData.courier_id === 'oth'){
            courierDialogData.relationships = [
              {
                type: 'courier',
                courier_name: this.inputDomesticCourierNew
              }
            ]
          }
          await DomesticService.editDomestic(this.selectedEditId, courierDialogData)
          // console.log(courierDialogData, 'edit')
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.useAsEdit = false
          this.courierDialog = false
        }
        this.getDomesticCourier()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    openDeleteModal(props) {
      this.selectedDomestiC = props.item
      this.selectedDelete = props.item
      this.showDeleteModal = true
    }

    cancelModalDomestiC() {
      this.domestic.selected = null
      this.showDomestiC = false
    }

    async deleteDomestic() {
      try {
        const payload = {
          id: [this.selectedDomestiC.id],
          action: 'delete'
        }
        this.showLoading({ text: 'Saving...' })
        this.showDeleteModal = false
        const response = await DomesticService.deleteDomestic(this.selectedDomestiC.id)
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
          color: response.status === 200 ? 'green' : 'red',
          timeout: 1500
        })
        this.getDomesticCourier()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    async bulkDeleteDomestic(){
      try {
        const payload = {
          data: {ids: this.selectedId}
        }
        this.showLoading({ text: 'Saving...' })
        this.modalBulkDelete = false
        const response = await DomesticService.bulkDelete(payload)
        this.showSnackbar({
          text: 'Success',
          color: 'green',
          timeout: 1500
        })
        this.checkboxAllSelected = false
        this.selectedId = []
        this.getDomesticCourier()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    openRateDetails(props) {
      this.rateDetailsObject = {
        shipping_method: startCase(props.item.shipping_method),
        calculation: props.item.calculation,
        minimum: props.item.minimum,
        maximum: props.item.maximum
      }
      this.detailsRateDialog = true
    }

    onNewAddCourier() {
      if (this.courierDialogData.courier_id == 'oth') {
        this.inputCourierItem = true
      } else {
        this.inputCourierItem = false
      }
    }

    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      }
    }

    toggleAll() {
      if (this.checkboxAllSelected === true) {
        forEach(this.tableDomesticCourier, domestic => {
          this.dataDomesticAllSelected = domestic
          this.selectedId.push(this.dataDomesticAllSelected.id)
        })
      } else {
        this.selectedId = []
      }
    }

    bulkDeleteButton() {
      this.modalBulkDelete = true
    }

    openImportData(props) {
      this.importDataDialog = {
        shipping_type: 'Domestic',
        origin_type: '',
        destination_type: '',
        country_id: props.item.id
      }
      this.modalImportData = true
    }

    closeImportData(){
      this.modalImportData = false
      ;(this.$refs as any).resetData.value = ''
    }


    //import Data ecxel
    importData(e, index) {
      const file = e.target.files[0]
      this.modelImportData = file
    }

    async attachExcel(){
      try{
        const validationResponse = await this.$validator.validateAll('importData')
        if(validationResponse){
          this.showLoading({ text: 'Saving...' })
          let configfile = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
              Identifier: 'manage'
            }
          }
          let data = new FormData()
          data.append('import_data', this.modelImportData)
          data.append('origin_type', lowerCase(this.importDataDialog.origin_type))
          data.append('destination_type', lowerCase(this.importDataDialog.destination_type))
          data.append('shipping_type', lowerCase(this.importDataDialog.shipping_type))
          data.append('country_id', lowerCase(this.importDataDialog.country_id))
          await DomesticService.importData(data, configfile)
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.modalImportData = false
        }
        this.getDomesticCourier()
        this.closeImportData()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }
}