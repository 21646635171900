import BaseService from '@/services/BaseService'

class CourierService extends BaseService {
  constructor() {
    super()
  }

  async getCouriers(opts = {}) {
    this.endPoint = 'api/v2/couriers'
    return this.get(opts)
  }

}

export default new CourierService()